(function($) {
    $(document).ready(function() {
        //change list view
        const linkSmall = $('#btnSmall');
        const linkBig = $('#btnBig');

        linkSmall.on('click', function(e) {
            e.preventDefault();
            $('.blog__list').addClass('blog__list-small');
            $('.blog__link .blog__icon').removeClass('blog__icon-active');
            $(this).find('.blog__icon').toggleClass('blog__icon-active');
        });

        linkBig.on('click', function(e) {
            e.preventDefault();
            $('.blog__list').removeClass('blog__list-small');
            $('.blog__link .blog__icon').removeClass('blog__icon-active');
            $(this).find('.blog__icon').toggleClass('blog__icon-active');
        });



        const dropdowns = document.querySelectorAll('.menu__tablet-dropdown');
        const arrows = document.querySelectorAll('.menu__tablet-icon.uk-icon'); // Select all arrow icons

        dropdowns.forEach((dropdown, index) => {
            const arrow = arrows[index]; // Get the corresponding arrow for each dropdown

            dropdown.addEventListener('beforeshow', function() {
                setTimeout(function() {
                    const currentLeft = parseInt(dropdown.style.left, 10) || 0;
                    dropdown.style.left = (currentLeft - 110) + 'px'; // Adjust by 110px to the left
                    console.log('Adjusted left before showing:', dropdown.style.left);

                    if (arrow) {
                        arrow.classList.add('rotate'); // Add rotation when dropdown is shown
                    }
                }, 10); // Small delay to allow UIkit to set position
            });

            dropdown.addEventListener('hide', function() {
                if (arrow) {
                    arrow.classList.remove('rotate'); // Remove rotation when dropdown is hidden
                }
            });
        });

       /* const dropdown = document.querySelector('.menu__tablet-dropdown');

        if (dropdown) {
            dropdown.addEventListener('shown', function() {
                console.log('shown');
                // Adjust the left position by 30px after UIkit sets it
                const currentLeft = parseInt(dropdown.style.left, 10) || 0;

                console.log('Original left:', currentLeft);
                dropdown.style.left = (currentLeft - 100) + 'px'; // Adjust by 30px

                console.log('Adjusted left:', dropdown.style.left);
            });
        }*/
    });

    document.addEventListener( 'wpcf7mailsent', function( event ) {
        UIkit.modal('#thank-you-modal').show();
    }, false );

    $('.menu__button').on('click', function () {
        const hasOpenClass = $('.menu__dropdown').hasClass('uk-open');

        $(this).toggleClass('uk-open');

        if (hasOpenClass) {
            $('#menu-overlay').removeClass('show');
        } else {
            $('#menu-overlay').addClass('show');
        }
    });

//alone link click
    $('.menu__item-link-alone').on('click',
        function(e) {
            const link = $(this).attr('href');
            console.log(link);
            window.location.href = link;
        }
    );


})(jQuery);

